






















































import {
  MinMaxTelemetryIndex,
} from '@/types/telemetry';
import { TranslateResult } from 'vue-i18n';
import { Component, Vue, Prop } from 'vue-property-decorator';
import ClassicMeter from '../../dataDisplayComponents/ClassicMeter.vue';

@Component({
  components: {
    ClassicMeter
  }
})
/**
 * @group Analytics Card
 * Card with semi circle gauges for displaying index values withing min and max ranges
 */
export default class GatewayControlCabinet1V1ControllerTelemetryCard extends Vue {
  @Prop({ type: Array, required: true }) readonly telemetries!: MinMaxTelemetryIndex[];

  isTotalEnergy(name: TranslateResult) {
    return name === this.$t('telemetries.totalEnergy');
  }

  isControl(name: TranslateResult) {
    return name === this.$t('telemetries.control');
  }

  isDoor(name: TranslateResult) {
    return name === this.$t('telemetries.doorOpen');
  }

  isInputsOrOutputs(name: TranslateResult) {
    return name === this.$t('telemetries.inputs')
      || name === this.$t('telemetries.outputs');
  }

  get inputsOutputsSection() {
    return this.telemetries.filter((t) => this.isInputsOrOutputs(t.name));
  }

  get totalEnergyReading() {
    return this.telemetries.find((t) => this.isTotalEnergy(t.name));
  }

  get controlReading() {
    return this.telemetries.find((t) => this.isControl(t.name));
  }

  get doorReading() {
    return this.telemetries.find((t) => this.isDoor(t.name));
  }
}
